import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 20px;
`;

export const Inner = styled.div`
	margin-top: 15px;
`;
