export const IGNORE_CODES_ARRAY = [
	8,
	16,
	17,
	18,
	20,
	27,
	37,
	38,
	39,
	40,
	91,
	93,
	112,
	113,
	114,
	115,
	116,
	117,
	118,
	119,
	120,
	121,
	122,
	123,
	124,
	125,
	126,
	222,
];

export const MAPPED_ICELANDIC_CHARS: { [key: string]: string } = {
	"189": "ö",
	"219": "ð",
	"191": "þ",
	"186": "æ",
	"187": "ö",
	"221": "ð",
	//"189": "þ",
	"192": "æ",
};

export const SPECIAL_CHARS = {
	accent: 222,
};

export const ACCENT_MAP: { [key: string]: string } = {
	a: "á",
	e: "é",
	i: "í",
	o: "ó",
	u: "ú",
	y: "ý",
	A: "Á",
	E: "É",
	I: "Í",
	O: "Ó",
	U: "Ú",
	Y: "Ý",
};
